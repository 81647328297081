.status {
  border: solid 2px;
  border-radius: 6px;
  padding: 6px;
  font-size: 12px;
}

.status--warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.status--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
