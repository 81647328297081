.button-group {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.button-group > .button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-group > .button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-group > .button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.button-group .button + .button {
  margin-left: -2px;
}
