.configure-game {
  display: flex;
  flex-direction: column;
}

.player {
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.player__info {
  display: flex;
  align-items: center;
}

.player__name {
  padding-left: 10px;
  white-space: nowrap;
  font-size: 26px;
}

.player__actions {
  display: flex;
  margin-top: 10px;
}

.player__icon {
  width: 36px;
  height: auto;
}

.configure-game__add-player {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
