.page-not-found__heading {
  margin: 0;
  text-align: center;
}

.page-not-found__title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
}

.page-not-found__error {
  font-size: 16px;
}

.page-not-found__content {
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.page-not-found--bold {
  font-weight: 700;
  font-style: italic;
}

.page-not-found__img {
  height: 200px;
  width: 200px;
  display: block;
  margin: 0 auto;
}

.page-not-found__footer {
  font-size: 16px;
  margin-top: 10px;
  display: flex;
  font-weight: 700;
  justify-content: flex-end;
}
