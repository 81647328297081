.game {
  width: 100%;
  max-width: 400px;
}

.game-player__section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.game-player__info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-player__name {
  margin-left: 4px;
  font-size: 20px;
}

.game-player__badges {
  display: flex;
  align-items: center;
}

.game-player__sex {
  margin-left: 12px;
  width: 30px;
}

.game-player__score {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions__section {
  text-align: center;
  font: 12px;
}

.actions__score {
  font-size: 12px;
}

.actions__combat-img {
  width: 36px;
  height: auto;
}

.actions__combat-score {
  text-align: center;
}

.game__actions {
  text-align: right;
  max-width: 500px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
