.combat {
  width: 100%;
  max-width: 450px;
}

.combat__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.combat__title {
  margin: 0;
}

.combat__icon {
  width: 40px;
  height: auto;
  margin-left: 8px;
}

.combat__section {
  margin-bottom: 32px;
}

.combat__combatant {
  font-size: 26px;
  margin-bottom: 8px;
}
