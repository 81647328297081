.button {
  font-family: "Sen", sans-serif;
  color: #4a1615;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: block;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 15px;
  border: solid 2px #4a1615;
  border-radius: 12px;
}

.button--fluid {
  width: 100%;
}

.button--style-reset {
  border: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
}

.button--info {
  color: #0c5460;
  background-color: #d1ecf1;
  border: solid 2px #bee5eb;
  border-radius: 6px;
  padding: 6px;
  font-size: 12px;
}
