body {
  background: url("./images/paper-texture.jpg") repeat;
  color: #4a1615;
  font-family: "Sen", sans-serif;
  min-height: 100vh;
}

body * {
  box-sizing: border-box;
}
