.score-input__actions {
  display: flex;
}

.score-input__button {
  padding: 4px 10px;
  font-size: 26px;
}

.score-input__score {
  font-size: 26px;
  width: 20px;
  text-align: center;
  align-self: center;
  padding: 4px 10px;
  box-sizing: content-box;
}
